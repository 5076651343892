export const bottom_banner_style = {
  height: "12vh",
  width: "70vw",
  paddingLeft: "15vw",
  paddingRight: "15vw",
}

export const banner_message_style = {
  display: "flex",
  flexDirection: "column" as "column"
}
