const video_1: string = "https://player.vimeo.com/video/221691838?title=0&byline=0&portrait=0";
const video_2: string = "https://player.vimeo.com/video/221691457?title=0&byline=0&portrait=0";
const video_3: string = "https://player.vimeo.com/video/221691225?title=0&byline=0&portrait=0";
const video_4: string = "https://player.vimeo.com/video/234611900?title=0&byline=0&portrait=0";

export const videos: string[] = [
  video_4,
  video_1,
  video_2,
  video_3,
];
